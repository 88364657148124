@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.5) 53%, rgba(0,0,0,0) 100%);

  &:before{
     //content:'';
     content: "";
     width: 100%;
     height: 100vh;
     position: fixed;
     left: 0;
     top: 0;
     z-index: -1;
     background-color: #000000;
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='p' width='100' height='100' patternUnits='userSpaceOnUse' patternTransform='scale(0.65)'%3E%3Cpath id='a' data-color='fill' fill='none' stroke='%23828282' stroke-width='2' d='M-25 20h50L0-20z'%3E%3C/path%3E%3Cuse xlink:href='%23a' x='100'%3E%3C/use%3E%3Cuse xlink:href='%23a' y='100'%3E%3C/use%3E%3Cuse xlink:href='%23a' x='100' y='100'%3E%3C/use%3E%3Cuse xlink:href='%23a' x='50' y='50'%3E%3C/use%3E%3Cuse xlink:href='%23a' x='50' y='150'%3E%3C/use%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23p)' width='100%25' height='100%25'%3E%3C/rect%3E%3C/svg%3E");
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.text-shadow {
  text-shadow: 0 2px 0 rgb(0 0 0 / 30%);
}

.adjacent-post {
  & .arrow-btn {
    transition: width 300ms ease;
    width: 50px;
  }
  &:hover {
    & .arrow-btn {
      width: 60px;
    }
  }
}


// react-multi-carousel
.react-multi-carousel-list {
  & .arrow-btn {
    transition: width 300ms ease;
    width: 50px;
    &:hover {
      width: 60px;
    }
  }
}


// scrollbar
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  outline: 1px solid slategrey;
}
